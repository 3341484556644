.container1 {
    margin-top: 1vh;
}

.navigationBar {
    text-align: center;
    /* margin-left: 47%; */
    font-size: large;
    /* padding-top: 1vh; */
    margin: 0;
    
    /* animation-duration: 3s;
    animation-name: slidein; */
}

.navigationText {
    /* text-align: left; */
    padding-left: 0px;
}

.navigationText:hover {
    color: white;
}

.buttonGroup {
    text-align: right;
}

.mediaCellText {
    text-align: left;
    padding-top: 1vh;
    padding-right: 0;
}

.gridOnOneLine {
    display: grid;
    grid-auto-flow: column;
}

.mediaPanel {
    overflow-y: scroll;
    min-height: 40vh;
    max-height: 40vh;
}

.overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /*Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.1); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /*Add a pointer on hover */
}

.spinnerStyle {
    margin-left: 50vw;
    margin-top: 50vh;
}
