
.cardOverall {
    border-width: 3px;
    border-color: rgb(33, 37, 41);
    border-radius: 10px;
    background-color: rgb(98, 127, 141);
    margin-bottom: 2vh;
}

.cardHeader {
    border-bottom-width: 3px;
    border-color: rgb(33, 37, 41);
}

.cardBody {
    background-color: rgb(110, 143, 158);
    border-radius: 5px;
    max-height: 70vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cardBody::-webkit-scrollbar {
    display: none;
  }