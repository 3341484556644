.button1 {
    border-radius: 25%;
    padding: 1vh;
    margin-right: 2vh; 
    margin-top: 2vh; 
    font-size: 130%; 
    border-width: 3px;
    font-weight: 600;
    background-color: rgb(105, 137, 152);
}

.navigationButton {
    border-radius: 25%;
    padding: .6vw;
    margin-right: .8vw;  
    font-size: medium; 
    border-width: 3px;
    font-weight: 600;
    background-color: rgb(105, 137, 152);
    text-align: right;
}

.mediaCellButton {
    border-radius: 25%;
    padding: .6vw;
    margin-right: .8vw;  
    font-size: medium; 
    border-width: 3px;
    font-weight: 600;
    background-color: rgb(80, 104, 116);
}

.mediaCellButtonVariant2 {
    border-radius: 25%;
    padding: .6vw;
    margin-right: .8vw;  
    font-size: medium; 
    border-width: 3px;
    font-weight: 600;
    background-color: rgb(98, 127, 141);
}

.mediaCellButtonDanger {
    border-radius: 25%;
    padding: .6vw;
    margin-right: .8vw;  
    font-size: medium; 
    border-width: 3px;
    font-weight: 600;
    background-color: rgb(62, 79, 87);
}

.modalButton {
    border-radius: 25%;
    font-size: medium; 
    border-width: 3px;
    font-weight: 600;
    background-color: rgb(80, 104, 116);
}

.modalButton2 {
    border-radius: 25%;
    font-size: medium; 
    border-width: 3px;
    font-weight: 600;
    background-color: rgb(80, 104, 116);
    margin-right: 1vw;
}